import { createStyles, Accordion, Container, Title } from '@mantine/core';
import React from "react";

const useStyles = createStyles((theme) => ({
    wrapper: {
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },
    title: {
        marginBottom: theme.spacing.md,
        paddingLeft: theme.spacing.md,
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
    item: {
        fontSize: theme.fontSizes.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
    },
}));

export function Faqs() {
    const { classes } = useStyles();
    return (
        <div className={classes.wrapper}>
            <Container size="lg">

				<Title order={1} ta="left" className={classes.title}>
					Frequently Asked Questions
				</Title>

				<Accordion chevronPosition="right" defaultValue="what-is-dropkey" variant="separated">

					<Accordion.Item className={classes.item} value="what-is-dropkey">
						<Accordion.Control>What is Dropkey</Accordion.Control>
						<Accordion.Panel>Dropkey allows you to securely store data for a temporary period to share. The data is encrypted with a password on your device, and can only be accessed and decrypted with the same password.</Accordion.Panel>
					</Accordion.Item>

					<Accordion.Item className={classes.item} value="is-free">
						<Accordion.Control>Is this service free to use?</Accordion.Control>
						<Accordion.Panel>Yes.</Accordion.Panel>
					</Accordion.Item>

					<Accordion.Item className={classes.item} value="how-secure">
						<Accordion.Control>How secure is my data?</Accordion.Control>
						<Accordion.Panel>Your data is encrypted on your device before it's sent to our servers. This means that nobody except you, not even us, can read your data without the password.</Accordion.Panel>
					</Accordion.Item>

					<Accordion.Item className={classes.item} value="how-long">
						<Accordion.Control>How long does my data stay in your system?</Accordion.Control>
						<Accordion.Panel>Your data will be stored for 5 minutes. After this period, it is automatically purged from our system.</Accordion.Panel>
					</Accordion.Item>

					<Accordion.Item className={classes.item} value="after-access-it">
						<Accordion.Control>
							What happens to my data after I access it?
						</Accordion.Control>
						<Accordion.Panel>Once your data is accessed using the unique URL, it is immediately purged from our system. You will need your password to decrypt the data on your device.</Accordion.Panel>
					</Accordion.Item>

					<Accordion.Item className={classes.item} value="forgot-password">
						<Accordion.Control>What if I forget my password or key?</Accordion.Control>
						<Accordion.Panel>Because your data is encrypted on your device, we don't have access to your password or key. If you forget it, we unfortunately can't recover your data.</Accordion.Panel>
					</Accordion.Item>


					<Accordion.Item className={classes.item} value="lost-url">
						<Accordion.Control>What should I do if I lose the unique URL?</Accordion.Control>
						<Accordion.Panel>The unique URL is the only way to retrieve your data. If it is lost, we cannot recover the URL or data for you. It's very important to keep the URL safe.</Accordion.Panel>
					</Accordion.Item>

					<Accordion.Item className={classes.item} value="without-url">
						<Accordion.Control>Can the data be accessed without the unique URL?</Accordion.Control>
						<Accordion.Panel>No, the unique URL is the only way to access the data. Without the URL, the data cannot be retrieved.</Accordion.Panel>
					</Accordion.Item>

					<Accordion.Item className={classes.item} value="extend-it">
						<Accordion.Control>Can I extend the time my data stays in your system?</Accordion.Control>
						<Accordion.Panel>No, at this time, data can only be stored for a maximum of 5 minutes.</Accordion.Panel>
					</Accordion.Item>

				</Accordion>

            </Container>
        </div>
    );
}
