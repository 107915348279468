import React, { useState } from 'react';
import { Button, Textarea, Modal, createStyles, CopyButton } from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import {Password} from "../components/password";
import CryptoJS from "crypto-js";
import {useDisclosure} from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
	wrapper: {
		width: '100%',
		maxWidth: '400px',
		margin: '2em auto 0',
	},
	copyButton: {
		marginTop: '10px',
	},
	copyButtonSpan: {
		marginLeft: '5px',
	},
	postPassword: {
		marginTop: '10px',
	}
}));

export function Peek() {
	const { classes } = useStyles();
	const [password, setPassword] = useState("");
	const [response, setResponse] = useState("");
	const [opened, { open, close }] = useDisclosure(false);

	const postPassword = async () => {
		// Extract the ID from the URL
		const id = window.location.pathname.split("/").pop();
		const url = `/api/peek`;

		try {
			const res = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ id })
			});

			const encryptedSecret = await res.text();

			// Decrypt the encrypted secret here
			let bytes = CryptoJS.AES.decrypt(encryptedSecret, password);
			let decryptedSecret = bytes.toString(CryptoJS.enc.Utf8);

			setResponse(decryptedSecret);
			open();
		} catch (error) {
			console.error("Error:", error);
		}
	};

	return (
		<>
			<div className={classes.wrapper}>
				<Password setPassword={setPassword} />

				<Button onClick={postPassword} fullWidth className={classes.postPassword}>
					Decrypt
				</Button>

				{response && (
					<>

						<Modal opened={opened} onClose={close} title="Decrypted data">
							<Textarea
								value={response}
								placeholder="Autosize with no rows limit"
								autosize
								minRows={2}
							/>

							<br/>

							<div className={classes.copyButton}>
								<CopyButton value={response}>
									{({ copied, copy }) => (
										<Button color={copied ? 'teal' : 'blue'} fullWidth onClick={copy}>
											Copy
											<span className={classes.copyButtonSpan}>
                                            {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                                        </span>
										</Button>
									)}
								</CopyButton>
							</div>
							<p>This secret has been deleted from our services and cannot be retrieved again.</p>
						</Modal>
					</>
				)}
			</div>
		</>
	);
}
