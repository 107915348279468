import {
	createStyles,
	Text,
	Input,
	rem,
	Button,
	TextInput,
	Switch,
	Modal,
	CopyButton,
	Textarea
} from '@mantine/core';
import {Password} from "./password";
import {SecretString} from "./secret-string";
import React, {useState} from "react";
import CryptoJS from "crypto-js";
import {IconAt, IconCheck, IconCopy} from "@tabler/icons-react";
import {useDisclosure} from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: `calc(${theme.spacing.xl} * 2)`,
        borderRadius: theme.radius.md,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[3]
        }`,

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column-reverse',
            padding: theme.spacing.xl,
        },
    },

    body: {
        margin: `0 auto`,
        width: `100%`,
        maxWidth: `400px`,

        [theme.fn.smallerThan('sm')]: {
            paddingRight: 0,
            marginTop: theme.spacing.xl,
        },
    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        lineHeight: 1,
        marginBottom: theme.spacing.md,
    },

    controls: {
        display: 'flex',
        marginTop: theme.spacing.xl,
    },

    inputWrapper: {
        width: '100%',
        flex: '1',
    },

    input: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: 0,
    },

    control: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
	copyButton: {
		marginTop: '10px',
	},
	copyButtonSpan: {
		marginLeft: '5px',
	}
}));

export function Create() {
    const { classes } = useStyles();

    const [secret, setSecret] = useState('');
    const [password, setPassword] = useState('');

	const [checked, setChecked] = useState(false);

	const [ip, setIp] = useState<string | null>(null);
	const [email, setEmail] = useState<string | null>(null);

    const [opened, { open, close }] = useDisclosure(false);
    // Add new state for the URL
    const [url, setUrl] = useState<string | null>(null);


    const handleDeploy = async () => {
        // Encrypt the secret here
        let encryptedSecret = CryptoJS.AES.encrypt(secret, password).toString();

        const response = await fetch('/api/create', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				secret: encryptedSecret,
				ip: ip,
				email: email,
			}),
        });

        const data = await response.json() as { url: string };
        console.log(data)
        // handle response
        setUrl(data.url);
        open();
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.body}>
                {/*<Title className={classes.title}>Share</Title>*/}

                <Text fz="md" c="dimmed">
                    To be as safe as possible, do not share the unique URL and password in the same medium.
                </Text>

				<br/>

                <SecretString setSecret={setSecret} />

                <br/>

                <Password setPassword={setPassword} />

                <br/>

                <Switch checked={checked} label="Advanced mode" onChange={(event) => setChecked(event.currentTarget.checked)} />

				{checked && (
					<>
						<br/>

						<TextInput
							label="IP address"
							description="If you want to use a IP range, e.g. 150.5. 1.0/150.5. 1.255. Just to use the 150.5."
							onChange={(event) => setIp(event.target.value)}
						/>

						<br/>

						<Input
							icon={<IconAt />}
							placeholder="Recipient emial address"
							onChange={(event) => setEmail(event.target.value)}
						/>
					</>
					)}

				<br/>
				<Button fullWidth onClick={handleDeploy}>
					Deploy
				</Button>

                <Modal opened={opened} onClose={close} title="Share URL">
                    {url ? (
						<>

							<Textarea
								autosize
								minRows={2}
								value={url}
								required
							/>

							<div className={classes.copyButton}>
								<CopyButton value={url}>
									{({ copied, copy }) => (
										<Button color={copied ? 'teal' : 'blue'} fullWidth onClick={copy}>
											Copy
											<span className={classes.copyButtonSpan}>
                                            {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                                        </span>
										</Button>
									)}
								</CopyButton>
							</div>
						</>
                    ) : (
                        <Text>Please deploy to generate the URL.</Text>
                    )}
                </Modal>

            </div>
        </div>
    );
}
