import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { PasswordInput } from '@mantine/core';
import { useInputState } from '@mantine/hooks';

interface PasswordProps {
    setPassword: Dispatch<SetStateAction<string>>;
}

export function Password({ setPassword }: PasswordProps) {
    const [value, setValue] = useInputState('');

    useEffect(() => {
        setPassword(value);
    }, [value, setPassword]);

    return (
        <div>
            <PasswordInput
                value={value}
                onChange={setValue}
                placeholder=""
                label="Password or key"
                required
            />
        </div>
    );
}
