import React, { Dispatch, SetStateAction } from 'react';
import { Textarea } from '@mantine/core';

interface SecretStringProps {
    setSecret: Dispatch<SetStateAction<string>>;
}

export function SecretString({ setSecret }: SecretStringProps) {
    return (
        <Textarea
            placeholder="Data to encrypt"
            label="Secret data"
            autosize
            minRows={2}
            onChange={(event) => setSecret(event.target.value)}
			required
        />
    );
}
