import { MantineProvider } from '@mantine/core';
import {HeaderMegaMenu} from "./components/header";
import {ClerkProvider, SignedIn, SignedOut, RedirectToSignIn} from "@clerk/clerk-react";
import {dark} from "@clerk/themes";

import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import {Home} from "./components/home";
import {Dashboard} from "./pages/dashboard";
import {Peek} from "./pages/peek";
import {Faqs} from "./pages/faqs";

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key")
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function PublicPage() {
    return (
        <>
            <MantineProvider withGlobalStyles withNormalizeCSS theme={{ colorScheme: 'dark' }}>
                <HeaderMegaMenu />

                <Home />
            </MantineProvider>
        </>
    );
}

function FaqsPage() {
    return (
        <>
            <MantineProvider withGlobalStyles withNormalizeCSS theme={{ colorScheme: 'dark' }}>
                <HeaderMegaMenu />

                <Faqs />
            </MantineProvider>
        </>
    );
}

function ProtectedPage() {
    return (
        <>
            <MantineProvider withGlobalStyles withNormalizeCSS theme={{ colorScheme: 'dark' }}>
                <HeaderMegaMenu></HeaderMegaMenu>
                <Dashboard></Dashboard>
            </MantineProvider>
        </>
    );
}

function PeekPage() {
	return (
		<>
			<MantineProvider withGlobalStyles withNormalizeCSS theme={{ colorScheme: 'dark' }}>
				<HeaderMegaMenu></HeaderMegaMenu>
				<Peek></Peek>
			</MantineProvider>
		</>
	);
}

function ClerkProviderWithRoutes() {
    const navigate = useNavigate();

    return (
        <ClerkProvider
            appearance={{
                baseTheme: dark
            }}
            publishableKey={clerkPubKey}
            navigate={(to) => navigate(to)}
        >
            <Routes>
                <Route path="/" element={<PublicPage />} />
                <Route path="/faqs" element={<FaqsPage />} />

                <Route
                    path="/dashboard"
                    element={
                        <>
                            <SignedIn>
                                <ProtectedPage />
                            </SignedIn>
                            <SignedOut>
                                <RedirectToSignIn />
                            </SignedOut>
                        </>
                    }
                />
				<Route
					path="/peek/:peekId"
					element={
						<>
							<SignedIn>
								<PeekPage />
							</SignedIn>
						</>
					}
				/>
            </Routes>
        </ClerkProvider>
    );
}

export default function App() {
  return (
      <BrowserRouter>
          <ClerkProviderWithRoutes />
      </BrowserRouter>

  );
}
